import api from '../utils/api';
import errHandler from './_err-handler';


export function fetch(query) {
  return api.get(`/v1/invoices${query ? `?${new URLSearchParams(query)}` : ''}`)
    .catch(errHandler);
}

export function get(id) {
  return api.get(`/v1/invoices/${id}`)
    .catch(errHandler);
}

export function pay(id) {
  return api.post(`/v1/invoices/${id}/charge`)
    .catch(errHandler);
}
