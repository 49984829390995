import { useInfiniteQuery, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import Loader from 'components/loader';
import * as actions from 'actions';
import ListLoadMoreButton from 'components/list-load-more-button';
import Button from 'components/button';


export default function SiteDetail_UnitList({
  cms,
  site,
  unitType,
  setModal,
}) {
  const limit = 50;
  const unitsInfiniteQuery = useInfiniteQuery({
    queryKey: `units-${site.code}-${unitType.code}`,
    queryFn: function ({ pageParam: offset = 0 }) {
      return actions.units.fetch(site.id, {
        offset,
        limit,
        typeId: unitType.id,
      });
    },
    getNextPageParam: function (lastPage, allPages) {
      return lastPage.length < limit ? undefined : allPages.reduce((a, p) => a + p.length, 0);
    },
  });

  const units = unitsInfiniteQuery.data?.pages.flat(1);

  const sitemapQuery = useQuery('sitemap-' + unitType.id, () => fetch(actions.sites.sitemapUrl(site.code, { typeId: unitType.id })).then(r => r.text()));

  if (unitsInfiniteQuery.isLoading) return <Loader />;

  const floors = [...new Set(units.map(u => u.floor))];

  function showSitemap() {
    setModal({
      content: <div dangerouslySetInnerHTML={{ __html: `${sitemapQuery.data}<svg height="400" class="w-100">${floors.map(floor => `<use href="#svg:${site.code}/${floor}"></use>`)}</svg>` }} />,
      size: 'xl',
    });
  }

  return (
    <>
      <ul className="list-unstyled d-flex flex-row flex-wrap justify-content-end gap-1 mt-2" style={{ backgroundColor: '#fffa' }}>
        {units?.map(unit => (
          <li key={unit.id}>
            <Button as={Link} to={`/sites/${site.code}/${unitType.code}?unitId=${unit.id}`} className="px-2 px-md-4" style={{ opacity: .8 }}>
              {unit.name.toUpperCase()}
            </Button>
          </li>
        ))}
      </ul>

      <div className="align-self-end">
        <ListLoadMoreButton
          infiniteQuery={unitsInfiniteQuery}
        />

        <Button onClick={showSitemap} variant="link" size="sm">Show on map</Button>
      </div>
    </>
  );
}
