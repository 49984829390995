import { cloneElement, Children } from 'react';
import { useInfiniteQuery } from 'react-query';
import styled from '@emotion/styled';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import wurd from 'wurd-react';
import * as actions from 'actions';
import store from 'store';
import Loader from 'components/loader';
import Paper from 'components/paper';
import Block from 'components/block';
import Empty from 'components/empty';
import Title from 'components/title';
import { getPrice } from 'utils/ui';


const cms = wurd.block('invoices');

const Table = styled.table({
  borderCollapse: 'separate',
  borderSpacing: '0 .5rem',
  fontSize: '.875rem',
  td: {
    verticalAlign: 'middle'
  },
  'td:first-of-type': {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  'td:last-of-type': {
    color: 'var(--brand)',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  '.sgc-cell': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '.25rem',
    /* '@media (min-width: 768px)': {
      flexDirection: 'row',
      gap: '.75rem',
      alignItems: 'center',
    } */
  },
  'tr': {
    transition: 'all .2s ease-in-out',
    '&:hover': {
      // boxShadow: '0 0 1.25rem 0 rgba(0,0,0,.15)',
      '> td:last-of-type': {
        color: 'var(--brand-hover)',
        filter: 'brightness(1.25)',
      }
    }
  }
});

export const StateLabel = styled.span({
  borderRadius: '.375rem',
  padding: '0 .375rem',
});

export const stateStyles = {
  paid: {
    color: 'var(--bs-success)',
    backgroundColor: 'rgba(var(--bs-success-rgb), .05)',
    border: 'var(--bs-success) 1px solid',
  },
  failed: {
    color: 'var(--bs-danger)',
    backgroundColor: 'rgba(var(--bs-danger-rgb), .05)',
    border: 'var(--bs-danger) 1px solid',
  },
  sent: {
    color: 'var(--bs-danger)',
    backgroundColor: 'rgba(var(--bs-danger-rgb), .05)',
    border: 'var(--bs-danger) 1px solid',
  }
};


export default function InvoiceListPage() {
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const limit = Number(params.limit) || 50;

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data,
  } = useInfiniteQuery('invoices', ({ pageParam: offset = 0 }) => actions.invoices.fetch({ ...params, offset, limit }), {
    getNextPageParam: (lastPage, allPages) => lastPage.length < limit ? undefined : allPages.reduce((a, p) => a + p.length, 0),
  });

  const invoices = data?.pages.flat(1);

  if (!invoices) return <Loader />;

  if (invoices.length === 0) return <Empty cms={cms.block('empty')} />;

  const { settings } = store.get();

  return (
    <Block>
      <Title cms={cms.block('title')} />

      <Table className="table table-borderless">
        <tbody>
          {invoices.map(invoice => {
            if (invoice.state === 'draft') return null;

            return (
              <TrLink key={invoice.sid} to={`/account/invoices/${invoice.sid.toUpperCase()}`}>
                <td className="p-1 p-sm-2 p-md-3 p-lg-4">
                  <div className="sgc-cell">
                    <div><strong>{moment(invoice.startDate).format(settings.dateFormats.short)}</strong></div>
                    <div className="text-muted text-uppercase">{invoice.sid}</div>
                  </div>
                </td>
                <td className="p-1 p-sm-2 p-md-3 p-lg-4">
                  <div className="sgc-cell">
                    <span>{getPrice(invoice.total)}</span>
                  </div>
                </td>
                <td className="p-1 p-sm-2 p-md-3 p-lg-4">
                  <div className="sgc-cell">
                    <StateLabel style={stateStyles[invoice.state]}><cms.Text id={invoice.state} /></StateLabel>
                  </div>
                </td>
                <td className="d-none d-sm-table-cell"><i className="fal fa-2x fa-chevron-right" /></td>
              </TrLink>
            );
          })}
        </tbody>
      </Table>
      {hasNextPage && (
        <button type="button" className="btn btn-link text-decoration-none p-1" onClick={fetchNextPage}>
          {isFetchingNextPage
            ? <i className="fa fa-spinner fa-spin me-1" />
            : <i className="fa fa-chevron-double-down me-1" />
          }
          <cms.Text id="loadMore"/>
        </button>
      )}
    </Block>
  );
}

function TrLink({ to, children, ...props }) {
  return (
    <Paper as="tr" {...props}>
      {Children.map(children, td => {
        return td && cloneElement(td,
          { className: `position-relative ${td.props.className || ''}` },
          <Link to={to} className="stretched-link text-reset text-decoration-none">{td.props.children}</Link>
        );
      })}
    </Paper>
  );
}
