import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Loader from 'components/loader';
import Paper from 'components/paper';
import ListLoadMoreButton from 'components/list-load-more-button';
import UnitTypeCard from './unit-type-card';


export default function SiteDetail_UnitTypeList({
  cms,
  site,
  unitTypes,
  infiniteQuery,
}) {
  const [modal, setModal] = useState();
  if (infiniteQuery.isLoading) return <Loader />;

  return (
    <>
      {unitTypes?.length > 0 ? (
        <>
          <ul className="list-unstyled d-flex flex-column gap-3 mt-2">
            {unitTypes.map(unitType => (
              <li key={unitType.id}>
                <UnitTypeCard unitType={unitType} site={site} setModal={setModal} />
              </li>
            ))}
          </ul>

          <ListLoadMoreButton
            infiniteQuery={infiniteQuery}
          />
        </>
      ) : (
        <Paper padded className="mt-5">
          <cms.Markdown id="empty.info" type="div" />
        </Paper>
      )}

      <Modal id="customFieldsModal" show={!!modal?.content} size={modal?.size} onHide={() => setModal()} dialogAs={modal?.dialogAs}>
        <button type="button" className="btn text-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => setModal()}><i className="text-white fal fa-times fa-2x" /></button>
        <div className="modal-body">
          {modal?.content}
        </div>
      </Modal>
    </>
  );
}
