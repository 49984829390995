import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import styled from '@emotion/styled';
import wurd from 'wurd-react';
import { getLangText, getPrice, getUnitPrice } from 'utils/ui';
import store from 'store';
import * as actions from 'actions';
import ButtonBase from 'components/button';
import FeatureList from 'components/feature-list';
import ListItemCardBase from 'components/list-item-card';
import Field from 'components/field';
import CustomInput from 'plugins/custom-fields/input';
import securitySystems from 'plugins/custom-fields/security-systems';


const cms = wurd.block('my-units');


const ListItemCard = styled(ListItemCardBase)({
  boxShadow: 'none',
  borderRadius: 0,
});

const Badge = styled.span({
  padding: '.4em .65em .35em',
  backgroundColor: '#0003',
});

const Icon = styled.i({
  marginRight: '.25rem'
});

const Button = styled(ButtonBase)({
  paddingLeft: 0,
  paddingRight: 0,
  '@media (max-width: 576px)': {
    padding: 0
  }
});


export default function UnitCard({ unit, unitType, site, setModal }) {
  const { settings, user } = store.get();
  const { invoicePeriod } = settings;
  const sitemapQuery = useQuery('sitemap-' + unit.id, () => fetch(actions.sites.sitemapUrl(site.code, { ids: unit.id })).then(r => r.text()), { enabled: !!(site && unit.floor) });

  if (!unit.rental) return null;

  const rentalFields = [
    ...Object.entries(unit.rental.accessCodes || {}),
    ...Object.entries(unit.rental.customFields || {})
      .map(([key, value]) => {
        return [key, value, settings.unitRentalCustomFields?.find(o => o.code === key)];
      }),
    ...Object.entries(user.customFields || {})
      .filter(([key]) => securitySystems.some(n => key.startsWith(n + '_')) && unit.rental.customFields?.[key] === undefined)
      .map(([key, value]) => {
        return [key, value, settings.userCustomFields?.find(o => o.code === key) || settings.unitRentalCustomFields?.find(o => o.code === key)]; // small hack, if user is admin, they get all their user cfs, even those not owner-readable, so we don't get their title, we can possibly fallback to unit rental one (sometimes we cache cf at user level with the same cf code), not a big issue as it's only for admins, but will try to fix in API
      }),
  ];

  function showAccessCodes() {
    setModal({
      content: (
        <>
          <h2 className="modal-title text-center mb-3"><cms.Text id="modal.title" /></h2>
          <div className="d-flex flex-column gap-2">
            {rentalFields.map(([key, value, cfSetting]) => {
              return (
                <Field
                  key={key}
                  cms={cms.block(`modal.${key}`)}
                  label={cfSetting && <label>{getLangText(cfSetting.title)}</label>}
                >
                  <CustomInput {...cfSetting} value={value} readOnly />
                </Field>
              );
            })}
          </div>
        </>
      ),
      size: 'sm',
    });
  }

  function showSitemap() {
    setModal({
      content: <div dangerouslySetInnerHTML={{ __html: `${sitemapQuery.data}<svg height="400" class="w-100"><use href="#svg:${site.code}/${unit.floor}"></use></svg>` }} />,
      size: 'xl',
    });
  }

  return (
    <ListItemCard image={unitType?.image}>
      <div className="d-flex justify-content-between flex-grow-1">
        <div className="mw-0">
          <h3 className="mb-1 text-truncate d-flex align-items-center"><cms.Text id="unit.name" vars={{ name: unit.name.toUpperCase() }} />{unit.state === 'reserved' && <cms.Text id="reserved" vars={{ startDate: moment(unit.rental.startDate).format(settings.dateFormats.short) }} type={Badge} className="fw-normal fs-7 ms-2" />}</h3>
          {unitType && <h4 className="fs-5 text-truncate">{getLangText(unitType.title)}</h4>}
          {unitType && <FeatureList iconsOnly tags={unitType.tags} />}
        </div>
        <div className="d-flex flex-column align-items-end">
          <span className="fs-5 fw-500">{getUnitPrice({ rental: unit.rental, type: unitType })}</span>
          <span className="text-nowrap"><cms.Text id={`per_${invoicePeriod}`} /></span>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-lg-2" style={{ gap: '.5rem' }}>
        {unit.rental.agreementUrl && (
          <Button as="a" href={unit.rental.agreementUrl} target="_blank" variant="link" size="sm">
            <Icon className="far fa-signature" />
            <cms.Text id="unit.agr.text" />
          </Button>
        )}

        {unit.state !== 'reserved' && (
          <Button as={Link} to={`/account/invoices?unitRentalId=${unit.rental.id}`} variant="link" size="sm">
            <Icon className="far fa-dollar-sign" />
            <cms.Text id="unit.invs.text" />
          </Button>
        )}

        {sitemapQuery.data && (
          <Button onClick={showSitemap} variant="link" size="sm">
            <Icon className="far fa-layer-group" />
            <cms.Text id="unit.sitemap.text" vars={{ floor: unit.floor }} />
          </Button>
        )}

        {rentalFields.length > 0 && (
          <Button onClick={showAccessCodes} variant="link" size="sm">
            <Icon className="far fa-shield-alt" />
            <cms.Text id="unit.acs.text" />
          </Button>
        )}
      </div>
    </ListItemCard>
  );
}
