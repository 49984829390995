import wurd from 'wurd-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import store from '../store';
import * as actions from '../actions';
import BillingPlugin from '../plugins/billing';
import Block from 'components/block';
import Title from 'components/title';


const cms = wurd.block('billing');

export default function Billing() {
  const user = store.get('user');
  const { data: unpaidInvoices } = useQuery('unpaid-invoices', () => actions.invoices.fetch({ state: 'unpaid' }));
  const navigate = useNavigate();
  const location = useLocation();

  function cleaupSessionId() {
    const sp = new URLSearchParams(location.search);
    sp.delete('session_id');
    sp.delete('_n');
    navigate(`${location.pathname}${`${sp}` ? `?${sp}` : ''}${location.hash}`);
  }

  return (
    <Block className="container mb-2">
      <Title cms={cms.block('title')} className="my-4" vars={user} />

      {unpaidInvoices?.length > 0 && (
        <div className="alert alert-warning d-flex gap-2 align-items-baseline my-4">
          <i className="fas fa-info-circle" />
          <cms.Markdown id="unpaidInvoicesWarning" vars={{ count: unpaidInvoices.length }} />
        </div>
      )}

      <BillingPlugin include_bankAccount onSuccess={cleaupSessionId} />
    </Block>
  );
}
