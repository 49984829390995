import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import wurd from 'wurd-react';

import config from 'config';
import * as actions from 'actions';

import Split from 'components/split-page';
import Loader from 'components/loader';
import Progress from 'components/unit-progress';
import UnitTypeCard from 'components/unit-type-detail/unit-type-card';
import DatePicker from 'components/unit-type-detail/date-picker';


const cms = wurd.block('unitDetail');


export default function UnitTypeDetail() {
  const { site: siteCode, type: typeCode } = useParams();
  const [searchParams] = useSearchParams();

  // Load site
  const { data: site, ...siteQuery } = useQuery({
    queryKey: ['site', siteCode],
    queryFn: () => actions.sites.get(siteCode),
    staleTime: config.queries.site.staleTime,
  });

  // Load unitType
  const { data: unitType, ...unitTypeQuery } = useQuery({
    queryKey: ['unitType', { siteCode, typeCode }],
    queryFn: () => actions.unitTypes.get(siteCode, typeCode),
    // TODO: load initialData from unitTypes list query, but react-query does not make this easy since they are fetched in groups
  });

  if (siteQuery.isLoading || unitTypeQuery.isLoading) return <Loader />;

  if (!site || !unitType) return <h1><cms.Text id="notFound" /></h1>;

  return (
    <>
      <Progress site={site} unitType={unitType} />

      <Split className="mb-2">
        <div className="flex-1"><UnitTypeCard site={site} unitType={unitType} /></div>
        <div className="flex-1 mt-lg-2"><DatePicker site={site} unitType={unitType} unitId={searchParams.get('unitId')} /></div>
      </Split>
    </>
  );
}
